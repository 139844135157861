<div class="global-navigation-bar">

  <a class="uitk-c-header-skip-to-content-link" href="#main-content">Skip to content</a>
  <uitk-header>
    <uitk-header-logo>
      <img class="uitk-c-header__logo" [src]="logoConfig?.logo.path"  style="height: 2.5rem;" [alt]="logoConfig?.logo.alt" />
      <label href="#" class="uitk-c-header__product-name" >RMS Worklist</label>
    </uitk-header-logo>
    <uitk-header-search>
      <div class="demo-l-nav__search">{{ navigationTypeGlobal }}</div>
    </uitk-header-search>
    <uitk-navigation-global>
      <label class="uitk-c-global-nav__menu-label">Menu Label</label>
      <uitk-navigation-panel>
        <uitk-navigation-panel-item *ngFor="let a of navigationBarData;let index = index">
          <a id="a{{index}}" uitkNavRoute routerLink="{{a.routeLink}}" (click)="onMenuItemClick(a.routeName)">
            {{a.routeName}}
          </a>
        </uitk-navigation-panel-item>

      </uitk-navigation-panel>


    </uitk-navigation-global>
  </uitk-header>  
</div>



