<div class="flex-container"> 
  <div *ngIf="showIsAcceptingCasesToggle" class="header-toggle">
    <uitk-form-field style="text-align:center">
      <label  uitkLabel>Accepting Cases</label>
      <uitk-toggle-switch [uitkId]="'isAcceptingCases'"
                          [checked]="isAcceptingCases"
                          (onChange)="handleisAcceptingCasesToggleChange($event)">
        <span *ngIf="isAcceptingCases"> Yes </span>
        <span *ngIf="!isAcceptingCases"> No </span>
      </uitk-toggle-switch>
    </uitk-form-field>
  </div>
</div>

<uitk-panel uitkId="search" [open]="openPanel" class="searchPanel" (openChange)="openChange($event)" collapsible="false">
  <uitk-panel-header-title style="display:inline-flex;float:left;width:101.5%;height:58px !important">
    <h1>Search</h1>
    <div id="headerpanel" (click)="onGlobalButtonsPanelClick($event)">
      <global-search id="globalsearchid" (globalSearchPanelClicked)="onGlobalSearchPanelClick($event)" [openPanel]="openPanel"></global-search>
      <div id="SearchButtonPanel" (click)="onGlobalButtonsPanelClick($event)">
        <div id="btns" (click)="onGlobalSearchClick()" style="display:inline-flex">
          <button id="btnGlobalSearch" *ngIf="!openPanel"
                  type="button"
                  class="uitk-c-button uitk-c-button--default uitk-u-margin--xs btn-opacity-shadow"
                  [disabled]="false">
            Search
          </button>
        </div>
        <div id="btnc" (click)="onGlobalClear()" style="display:inline-flex">
          <button id="btnGlobalClear" *ngIf="!openPanel"
                  class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xs btn-opacity-shadow"
                  type="button"
                  [disabled]="false">
            Clear
          </button>
        </div>
      </div>
    </div>
  </uitk-panel-header-title>

  <uitk-panel-content *ngIf="hasWorklistAccess">

    <form #worklistForm="ngForm" novalidate>
      <basic-search></basic-search>
    </form>
    <div id="advancepanel">
      <uitk-panel uitkId="advanceuitkpanel" [open]="openadvancedPanel" (openChange)="openAdvancedChange($event)">
        <uitk-panel-header-title class="advancedheadertitle">
          <h2 class="advanced-header">Advanced Search</h2>
          <div class="advanced-unclickable-panel" (click)="onAdvancedSearchPanelClick($event)">
            <div class="uitk-l-grid__row uitk-u-flex__align--right" *ngIf="!openadvancedPanel" style="display:inline-flex;width:100%">
              <button id="btnSearch" (click)="onSearchFilter()"
                      type="button"
                      class="uitk-c-button uitk-c-button--default uitk-u-margin--xs"
                      [disabled]="false">
                Search
              </button>
              <button id="btnClear" (click)="onClear()"
                      class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xs"
                      type="button"
                      [disabled]="false">
                Clear
              </button>
            </div>
          </div>
        </uitk-panel-header-title>
        <uitk-panel-content>
          <div>
            <advanced-search></advanced-search>
          </div>
        </uitk-panel-content>
      </uitk-panel>
    </div>
    <div class="uitk-l-grid__row uitk-u-flex__align--right" *ngIf="openadvancedPanel">
      <button id="btnSearch" (click)="onAdvancedSearchClick()"
              type="button"
              class="uitk-c-button uitk-c-button--default uitk-u-margin--xs"
              [disabled]="false">
        Search
      </button>
      <button id="btnClear" (click)="onAdvancedSearchClear()"
              class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xs"
              type="button"
              [disabled]="false">
        Clear
      </button>
    </div>
  </uitk-panel-content>
</uitk-panel>

<div class="uitk-l-grid__row ">
  <div class="uitk-l-grid__col">
    <div class="uitk-l-grid__row uitk-u-flex__align--left">
      <div class="uitk-u-padding-top--xl" style="font-size: 17px;">
        <span *ngIf="recordCount==null"> No Results</span>
        <span *ngIf="recordCount==1"> {{recordCount}}   Result</span>
        <span *ngIf="recordCount!=1"> {{recordCount}}   Results</span>
      </div>
    </div>
  </div>
  <div class="uitk-l-grid__col">
    <div class="uitk-l-grid__row uitk-u-flex__align--right">
      <div class="uitk-u-padding-top--sm">
        <button id="create-new-case-btn"
                type="button"
                class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xs"
                (click)="createNewCase()">
          Create a New Case
        </button>
      </div>
      <div *ngIf="caseOpenInCaseViewGranted && hasMyNextReviewAccess" class="uitk-u-padding-top--sm">
        <button id="ghost-btn" type="button" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xs" [disabled]="!mnrButtonEnabled" (click)="MyNextCase()">
          My Next Review
        </button>
      </div>
    </div>
  </div>
</div>
<div class="uitk-c-table--responsive">
  <div uitkTableContainer [sticky]="true" [height]="'500px'" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
       [alwaysCallback]="true" (scrolled)="onScroll()" [scrollWindow]="false">
    <table uitkTable
           [dataSource]="workListTableData"
           id="basic-table"
           class="uitk-c-table"
           aria-describedby="row-data">
      <thead class="uitk-c-table__head">
        <tr uitkTableSort #sortTable="uitkTableSort">
          <th uitkTableSortColumn
              (sortChange)="onSortChange($event)"
              [column]="headerName.id"
              *ngFor="let headerName of workListTableHeader"
              scope="col">
            {{ headerName.name }}
          </th>
        </tr>
      </thead>
      <tbody id="row-data" class="uitk-c-table__body" *ngIf="hasWorklistAccess">
        <ng-container *uitkTableRowBody="let record">
          <tr class="uitk-c-table__row">
            <td>{{ record.hospitalName }}</td>
            <td><u *ngIf="showPatientNameAsLink"><a href="javascript:void(0);" title="" (click)="openAssessmentInAppropriateWorkspace(record)">{{ record.patientName }}</a></u><span id="showPatientNameNotAsLinkId" *ngIf="!showPatientNameAsLink">{{ record.patientName }}</span></td>
            <td>{{ record.formattedAdmitDate }}</td>
            <td>
              {{record.inUse}}<span *ngIf="record.inUse && unlockPermissionGranted" class="uitk-icon uitk-icon-lock sm-icon" (click)="showNormalDialog(record.assessmentId)"></span>
            </td>
            <td>
              {{record.status}}
            </td>
            <td>
              <span [ngClass]="{'past-due': record.due && record.due < 0}">
                {{record.due}}
              </span>
            </td>
            <td>{{ record.assessmentType }}</td>
            <td>
              <span *ngIf="record.assessmentType == 'Denial' && !record.isPayerRefused && record.payorName != null">
                <uitk-tooltip-ex id="payerRefusal_tool_tip"
                                 [isDynamicallyPositioned]="false"
                                 [tooltipPlacement]="'top'">
                  <span class="uitk-icon uitk-icon-checkmark_hollow payerApproved"></span>

                  <span uitkTooltipContent>
                    <span>Payer Approved</span>
                  </span>
                </uitk-tooltip-ex>
              </span>
              <span *ngIf="record.assessmentType == 'Denial' && record.isPayerRefused && record.payorName != null">
                <uitk-tooltip-ex id="payerRefusal_tool_tip"
                                 [isDynamicallyPositioned]="false"
                                 [tooltipPlacement]="'top'">
                  <span class="uitk-icon uitk-icon-prohibited_slash sml-icon"></span>

                  <span uitkTooltipContent>
                    <span>Payer Refused</span>
                  </span>
                </uitk-tooltip-ex>
              </span>
              {{ record.payorName }}
            </td>
            <td>{{ record.caseManager }}</td>
            <td>{{ record.attendingPhysician }}</td>
            <td>{{ record.assignedToName}}</td>
            <td>
              <span id="activitycolid" *ngIf="record.activityShortNameList?.length === 1">
                <a style="text-decoration:underline" (click)="openActivityDialog(record)"> {{ record.activityShortName }} </a>
              </span>
              <uitk-tooltip-ex class="date_tool_tip" *ngIf="record.activityShortNameList?.length > 1"
                               id="admitdate_tool_tip"
                               [tooltipId]="'tooltipBottomStatus'"
                               [isDynamicallyPositioned]="false"
                               [tooltipPlacement]="'bottom'">
                <span style="border-bottom:none !important;"><a style="text-decoration:underline" (click)="openActivityDialog(record)">{{ record.activityShortName }}</a></span>
                <span class="a11y-assistive-text">&nbsp;</span>
                <span class="uitk-icon uitk-icon-arrow_down sml-icon activity-col-val"></span>
                <span uitkTooltipContent>
                  <ul class="activity-tootip-content">
                    <li class="activity-tootip-content-align" *ngFor="let shortName of record.activityShortNameList">
                      <span>{{ shortName }}</span>
                    </li>
                  </ul>
                </span>
              </uitk-tooltip-ex>
            </td>
            <td>{{ record.assessmentId }}</td>
            <td>
              <span *ngIf="record.notificationMessage?.length < 1">{{record.notificationMessage}}</span>
              <u *ngIf="record.isContractedClient && record.notificationMessage?.length > 0 && record.notificationMessage.substring(0,2) == 'DC'">
                <a href="javascript:void(0);" title="" (click)="openAssessmentInAppropriateWorkspace(record)">
                  {{ record.notificationMessage }}
                </a>
              </u>
              <span style="justify-content: center;" *ngIf="record.notificationMessage?.length > 0 && record.notificationMessage.substring(0,3) == 'SLR'">
                {{ slrNotificationFormat(record.notificationMessage, 0) }}
                <br>
                {{ slrNotificationFormat(record.notificationMessage, 1) }}
              </span>
              <span *ngIf="!record.isContractedClient && record.notificationMessage?.length > 0 && record.notificationMessage.substring(0,2) == 'DC'">
                {{ record.notificationMessage }}
              </span>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div class="u-dialog">

    <uitk-dialog [(showModal)]="normalDialogModal.show"
                 uitkId="myDialog-1"
                 [closeButtonText]="closeButtonText" tabindex="0" autofocus (onModalHidden)="closeDialog()">
      <uitk-dialog-header>
        Unlock Case
      </uitk-dialog-header>
      <uitk-dialog-content>
        <p class="p-content">Please confirm you wish to unlock this case</p>
        <form class="form-content" #myMultiSelectForm="ngForm">
          <uitk-form-field>
            <label uitkLabel>Assign To</label>

            <uitk-select class="u-dropdown"
                         uitkId="unlock-template"
                         id="singleselect-UnlockCase"
                         [itemList]="assignableUsers"
                         [required]='false'
                         #singleSelect="ngModel"
                         name="template-example"
                         aria-errormessage="assignableUsers is required"
                         [(ngModel)]="selectedUser"
                         defaultLabel='Select user'
                         (onDropdownChange)="onAssignToUsersDropdownChange($event)">
            </uitk-select>

          </uitk-form-field>

        </form>


      </uitk-dialog-content>
      <uitk-dialog-actions>
        <div style="text-align: right;">
          <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="unlockAssessment()">
            Confirm
          </button>
          <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="closeDialog()">
            Cancel
          </button>
        </div>
      </uitk-dialog-actions>
    </uitk-dialog>

    <uitk-dialog [(showModal)]="showCaseInUseDialog"
                 uitkId="showCaseInUseDialog"
                 triggerElementId="myDialog-1_openBtn"
                 [closeButtonText]="closeButtonText">
      <uitk-dialog-header>
        Maximum Open Cases ({{maxCaseInUseCount}})
      </uitk-dialog-header>
      <uitk-dialog-content>
        <p>
          You have reached the maximum number of open cases.
          In order to open a new one, please exit at least one case.
          If you are not sure which cases you have open, go to your worklist,
          <span style="text-decoration:underline ;color:#005187;">My Recent Cases.</span>
          It will specify which cases you currently have locked or are browsing.
        </p>
      </uitk-dialog-content>
      <uitk-dialog-actions>
        <div style="text-align: right;">
          <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="closeShowCaseInUseDialog()">
            {{closeButtonText}}
          </button>
        </div>
      </uitk-dialog-actions>
    </uitk-dialog>
    <uitk-page-notification uitkId="warning-msg" [(visible)]="showInUseWarningDialog" pageNotificationType="warning"
                            triggerElementId="warning-msg-trigger" animationType="fadeAnimation">
      <p>This assessment is currently in use by <strong>{{currentInUse}}</strong>. Do you wish to continue in read only mode?</p>
      <div class="uitk-u-flex__align--right uitk-u-margin-top--sm">
        <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="continueCase()">
          Continue
        </button>
        <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="closeWarningDialog()">
          Go Back
        </button>
      </div>
    </uitk-page-notification>

  </div>

</div>
<div *ngIf="displayIndicator" class="loader">
  <uitk-loading-indicator uitkId="my-spinner-1"
                          [visibleText]="loadingText"
                          spinnerSize="large"
                          [displayOverlay]="true">
  </uitk-loading-indicator>
</div>
<div *ngIf="activityDialog.show">
  <app-activity-dialog [(showActivityDialog)]="activityDialog.show"
                       [assessmentId]="currentAssessmentId"
                       [activityDetailsList]="activityDetailsList"
                       (closeActivityDialogEvent)="closeActivityDialog()">
  </app-activity-dialog>
</div>
