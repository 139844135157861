<header class= "navbar-element">
    <app-navigation-bar></app-navigation-bar>
</header>
<main id="main-content" class="wrapper" tabindex="-1" style="outline:none">

  <div class="uitk-l-grid">
    <div class="uitk-l-grid__row">
      <div class="uitk-l-grid__col">
        <div class="demo-l-grid-col-content">
          <app-worklist></app-worklist>
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
  <!-- Success Notification -->
  <uitk-notification [show]="notificationService.successMessages.visible" [uitkId]="notificationService.successMessages.id"
                     [variant]="notificationService.successMessages.messageType" [displayButton]="notificationService.successMessages.isCloseEnabled"
                     [buttonText]="notificationService.successMessages.closeText" (onButtonClick)="notificationService.hideSuccess()">
    <span>{{notificationService.successMessages.content}}</span>
  </uitk-notification>
  <!-- Error Notification -->
  <uitk-notification [show]="notificationService.errorMessages.visible" [uitkId]="notificationService.errorMessages.id"
                     [variant]="notificationService.errorMessages.messageType" [displayButton]="notificationService.errorMessages.isCloseEnabled"
                     [buttonText]="notificationService.errorMessages.closeText" (onButtonClick)="notificationService.hideErrors()">
    <span [innerHTML]="notificationService.errorMessages.content | keepHtml" ></span>
  </uitk-notification>
  <!-- Warning Notification -->
  <uitk-notification [show]="notificationService.warningMessages.visible" [uitkId]="notificationService.warningMessages.id"
                     [variant]="notificationService.warningMessages.messageType" [displayButton]="notificationService.warningMessages.isCloseEnabled"
                     [buttonText]="notificationService.warningMessages.closeText" (onButtonClick)="notificationService.hideWarnings()">
    <span [innerHTML]="notificationService.warningMessages.content | keepHtml"></span>   
  </uitk-notification>
  <timeout></timeout>
</main>
